import NextLink from 'next/link';
import { useRouter } from 'next/router';

import type { AnchorHTMLAttributes, ReactNode } from 'react';
import type { SerializedStyles } from '@emotion/react';
import tw, { css } from 'twin.macro';

import { checkIsUrl } from '@utils/helpers';
type Color = 'primary' | 'success' | 'warning' | 'error';
type Variant = 'contained' | 'outlined' | 'text';

type StyleProps = { [key in Variant]: { [key in Color]: SerializedStyles } };

type Props = {
  children: ReactNode;
  color?: Color;
  fullWidth?: boolean;
  href?: string;
  styles?: string | null;
  type?: string;
  variant?: Variant;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = ({
  children,
  color = 'primary',
  fullWidth = false,
  href = '#',
  styles = null,
  type = 'text',
  variant = 'text',
  ...restProps
}: Props) => {
  const router = useRouter();

  const variantButtonStyle: StyleProps = {
    contained: {
      primary: css`
        ${tw`text-white bg-primary border-primary hover:(bg-primary-hover text-white border-primary-hover)`};
      `,
      success: css`
        ${tw`text-white bg-green-1 border-green-1 hover:(bg-green-1 border-green-1 text-white)`};
      `,
      warning: css`
        ${tw`text-white bg-yellow-0 border-yellow-0 hover:(bg-yellow-0 border-yellow-0 text-white)`};
      `,
      error: css`
        ${tw`text-white bg-red-2 border-red-2 hover:(bg-red-2 border-red-2 text-white)`};
      `,
    },
    outlined: {
      primary: css`
        ${tw`border-primary text-primary hover:(bg-primary text-white)`};
      `,
      success: css`
        ${tw`border-green-1 text-green-1 hover:(bg-green-1 text-white)`};
      `,
      warning: css`
        ${tw`border-yellow-0 text-yellow-0 hover:(bg-yellow-0 text-white)`};
      `,
      error: css`
        ${tw`border-red-2 text-red-2 hover:(bg-red-2 text-white)`};
      `,
    },
    text: {
      primary: css`
        ${tw`text-primary hover:(text-primary-hover)`};
      `,
      success: css`
        ${tw`text-green-1 hover:(text-green-1)`};
      `,
      warning: css`
        ${tw`text-yellow-0 hover:(text-yellow-0)`};
      `,
      error: css`
        ${tw`text-red-2 hover:(text-red-2)`};
      `,
    },
  };

  const twLinkStyle = [
    type === 'button' && [
      tw`inline-block text-sm font-bold tracking-wide text-center rounded-md align-middle duration-300 border border-transparent cursor-pointer select-none focus-visible:(ring-2 outline-none ring-offset-2) hover:(no-underline transition-all duration-300) px-6 py-3`,
      fullWidth && tw`w-full`,
      variantButtonStyle[variant][color],
    ],
    styles,
  ].filter(Boolean);
  if (checkIsUrl(href)) {
    return (
      <a
        href={href}
        css={twLinkStyle}
        target="_blank"
        rel="noopener noreferrer"
        {...restProps}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} locale={router?.locale} legacyBehavior>
      <a css={twLinkStyle} {...restProps}>
        {children}
      </a>
    </NextLink>
  );
};

Link.displayName = 'Link';

export default Link;
